import React, { Component } from 'react'
import RichText from '../RichText/RichText';
import Heading from '../Heading/Heading';
import { richtext } from '../../types/richtext';
import * as style from './event-calendar.module.css';
import * as Sectionstyle from '../Section/section-style.module.css';

type Props = {
  headline: string,
  events: [
    {
      headline: string
      description: richtext
      date: string
    }
  ]
}

class EventCalendar extends Component<Props> {

  render() {
    return (
      <div>
        <section className={Sectionstyle.section}>
            <div className={Sectionstyle.inner}>
              <div className={Sectionstyle.text}>
                <Heading level="h2" style={{ marginTop: 0 }}>{this.props.headline}</Heading>
                <ul className={style.list}>
                {this.props.events.map((event, index) => (
                  <li key={index}>
                    <time className={style.time}>{event.date}</time>
                    <div>
                      <hr className={style.hr} />
                      <h3 className={style.location}>{event.headline}</h3>
                      <RichText {...event.description} />
                    </div>
                  </li>
                ))}
                </ul>
              </div>
          </div>
        </section>
      </div>
    )
  }
}
export default EventCalendar