import React, { Component } from 'react';
import { richtext } from '../../types/richtext';
import Heading from '../Heading/Heading';
import Menu from './Menu';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as style from './menusection-style.module.css';

type Props = {
  headline: string
  image: {}
  menus: [
    {
      headline: string,
      content: richtext
    }
  ]
}

class MenuSection extends Component<Props> {

  render() {
    const { headline, menus, image} = this.props;
    // const half = Math.floor(menus.length / 2);


    return (
      <section className={style.section}>
        <div >
          <GatsbyImage loading="lazy" image={getImage(image)} alt="Menu billede" />
        </div>
        <div className={style.content}>
          <Heading style={{marginTop: 0}} level="h2">{headline}</Heading>
          <div className={style.menuWrapper}>
            <div className={style.menus}>
              {menus.map((menu, index) => <Menu key={index} {...menu} />)}
            </div>
            {/* <div className={style.menus}>
              {menus.slice(half).map((menu, index) => <Menu key={index} {...menu} />)}
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default MenuSection;