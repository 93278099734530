// <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42z"/></svg>

import React, { Component } from 'react';

class Arrow extends Component {
  render() {
    return (
      <svg width="12" height="18" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M.885 15.87L7.755 9 .885 2.115 3 0l9 9-9 9z" fillRule="evenodd" />
      </svg>
    );
  }
}

export default Arrow;
