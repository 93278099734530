import React, { Component } from 'react'
import Section from './Section';
import { contentfulSection } from '../../types/contentful-section'

type Props = {
  id: string
  sections: contentfulSection
  theme?: 'grey' | 'default'
  reversed?: boolean
}

class SectionWrapper extends Component<Props> {
  render() {
    const { id, sections, theme, reversed } = this.props

    const edge = sections.edges.filter(_edge => {
      return _edge.node.contentfulid === id
    })

    return <Section section={edge[0].node} theme={theme} reversed={reversed} />
  }
}
export default SectionWrapper