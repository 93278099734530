import React, { Component } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './slick-slider.css';
import MediaSliderArrow from './MediaSliderArrow';
import * as style from './mediaslider-style.module.css';

type Props = {
  slides: []
}

export default class MediaSlider extends Component<Props> {

  state = {

  };

  render() {
    const {slides} = this.props;

    const settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 8000,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      prevArrow: <MediaSliderArrow />,
      nextArrow: <MediaSliderArrow right />
    }

    return (
      <section className={style.slider}>
        <Slider {...settings}>
          {slides.map((media, index) => {
            const img = getImage(media);
            if (!img) return null;
            return ( <GatsbyImage loading='lazy' image={img} key={index} alt="mad billede" />)
          })}
        </Slider>
      </section>
    );
  }
}