import React, { Component } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import RichText from '../RichText/RichText';
import { section } from '../../types/section';
import * as style from './section-style.module.css';
import Heading from '../Heading/Heading';
// import styles from '../MenuSection/menusection-style';
import 'intersection-observer';
import { InView } from 'react-intersection-observer'
import classnames from 'classnames';

type Props = {
  section: section,
  theme?: string
  reversed?: boolean
}

class Section extends Component<Props> {
  render() {
    const { image, headline } = this.props.section;
    const sectionClass = this.props.theme === 'grey' ? style.sectionAlternate : style.section;
    const innerClass = this.props.reversed ? style.innerReversed : style.inner;

    return (
      <section className={sectionClass}>
        <div className={innerClass}>
          {typeof window !== 'undefined' &&
            <InView tag="div" threshold={0.5} triggerOnce>
              {({ inView, ref }) => (
                  <div ref={ref} className={classnames(style.image, inView && style.inView)}>
                    {<GatsbyImage loading="lazy" image={getImage(image)} alt="" />}
                  </div>
              )}
            </InView>
          }
          <div className={style.text}>
            <Heading level="h2" style={{marginTop: 0}}>{headline}</Heading>
            <RichText {...this.props.section.content} />
          </div>
        </div>
      </section>
    )
  }
}
export default Section