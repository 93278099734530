import React, { Component } from 'react';
import Arrow from '../../icons/Arrow';
import * as style from './mediaslider-style.module.css';

type Props = {
  onClick?: Function,
  right?: boolean
}

class MediaSliderArrow extends Component<Props> {
  handleClick = () => this.props.onClick && this.props.onClick();

  render() {

    return (
      <button aria-label={this.props.right ? 'Næste' : 'Forrige'} className={this.props.right ? style.arrowright : style.arrow} onClick={this.handleClick}>
        <Arrow />
      </button>
    );
  }
}

export default MediaSliderArrow;