import React, { Component } from 'react';
import { richtext } from '../../types/richtext';
import Heading from '../Heading/Heading';
import RichText from '../RichText/RichText';
import * as styles from './menusection-style.module.css';

type Props = {
  headline: string
  content: richtext
}
class Menu extends Component<Props> {
  render() {
    const {headline, content} = this.props;

    return (
      <div className={styles.menu}>
        {headline && <Heading  level="h4">{headline}</Heading>}
        <RichText {...content} />
      </div>
    );
  }
}

export default Menu;