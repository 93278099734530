import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout';
import SectionWrapper from '../components/Section/SectionWrapper';
import { contentfulSection } from '../types/contentful-section'
import Intro from '../components/Intro/Intro';
import MediaSlider from '../components/MediaSlider/MediaSlider';
import MenuSection from '../components/MenuSection/MenuSection';
import Media from '../components/Media/Media';
import EventCalendar from '../components/EventCalendar/EventCalendar';

type Props = {
  data: {
    allContentfulFrontpageSection: contentfulSection,
    allContentfulIntro: {edges: {}},
    allContentfulFooter: {},
    allContentfulGallery: {edges: {}}
    allContentfulMenuSection: {edges: {}}
    allContentfulFindOsHer: {nodes: {findOsHer: {}, event: []}},
  }
}

class IndexPage extends Component<Props> {

  render() {
    const {data} = this.props;

    const onlyDummyEvents = data.allContentfulFindOsHer.nodes[0].event.length === 1 && data.allContentfulFindOsHer.nodes[0].event[0].headline === 'dummy';
    const events = !onlyDummyEvents && data.allContentfulFindOsHer.nodes[0].event.filter(event => event.headline !== 'dummy');
    const video = data.allContentfulVideo.edges[0].node.video.file.url;
    return <Layout>
      <main>
        <Media src={video} />
        <Intro {...data.allContentfulIntro.edges[0].node} theme="grey" />
        {!onlyDummyEvents && <EventCalendar headline={data.allContentfulFindOsHer.nodes[0].findOsHer} events={events}/> }
        <SectionWrapper sections={data.allContentfulFrontpageSection} id="who_we_are" theme={!onlyDummyEvents ? 'grey' : 'default'} />
        <MediaSlider slides={data.allContentfulGallery.edges[0].node.image} />
        <SectionWrapper sections={data.allContentfulFrontpageSection} id="what_we_do" reversed />
        {/* <SectionWrapper sections={data.allContentfulFrontpageSection} id="smokeren" reversed /> */}
        <MenuSection {...data.allContentfulMenuSection.edges[0].node} />
      </main>
    </Layout>
  }
}
export default IndexPage

export const query = graphql`
  query {
    allContentfulVideo {
      edges {
        node {
          video {
            id
            file {
              fileName
              url
            }
          }
        }
      }
    }

    allContentfulMenu {
      edges {
        node {
          headline
        }
      }
    }

    allContentfulGallery {
      edges {
        node {
          image {
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.7777777778)
          }
        }
      }
    }

    allContentfulMenuSection {
      edges {
        node {
          headline
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          menus {
            headline
            content {
              raw
            }
          }
        }
      }
    }

    allContentfulFrontpageSection {
      edges {
        node {
          contentfulid
          headline
          content {
            raw
          }
          image {
          gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allContentfulIntro {
      edges {
        node {
          headline
          content {
            raw
          }
        }
      }
    }
    allContentfulFindOsHer {
      nodes {
        id
        findOsHer
        event {
          id
          headline
          date
          description {
            raw
          }
        }
      }
    }
  }
`
