import React, { Component } from 'react'
import RichText from '../RichText/RichText';
import Heading from '../Heading/Heading';
import { richtext } from '../../types/richtext';
import * as style from './intro-style.module.css';

type Props = {
  headline: string
  content: richtext
}

class Intro extends Component<Props> {
  render() {
    const { headline, content } = this.props;

    return (
      <section className={style.section}>
        <div className={style.inner}>
          <div className={style.text}>
            <Heading level="h1" style={{ marginTop: 0 }}>{headline}</Heading>
          </div>
          <div className={style.text}>
            <RichText {...content} />
          </div>
        </div>
      </section>
    )
  }
}
export default Intro