import React, { Component } from 'react'
import * as style from './media.module.css';
import SoundOff from '../../icons/SoundOff';
import SoundOn from '../../icons/SoundOn';

type Props = {
  src?: string
}

class Media extends Component<Props> {
  video: React.RefObject<HTMLVideoElement> = React.createRef();

  state = {
    sound: false
  };

  toggleAudio = () => {
    if (this.video?.current) {      
      this.setState({sound: !this.state.sound});
      this.video.current.muted = !this.video.current.muted;
    }
  }

  render() {
    const { src } = this.props;

    return (
      <section className={style.media}>
        <video ref={this.video} autoPlay muted loop className={style.video}>
          <source src={src ?? "/static/test.mp4"} type="video/mp4" />
        </video>
        <button className={style.toggleSound} aria-label="afspil lyd" onClick={this.toggleAudio}>
          {!this.state.sound ? <SoundOff /> : <SoundOn />}
        </button>

      </section>
    )
  }
}
export default Media